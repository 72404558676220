import { Component, Prop } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
    name: 'GtrRegistrationSendReceiptView'
})

export default class GtrRegistrationSendReceiptView extends GtrSuper {
    @Prop()
    design: any;

    data() {
        return {
            showSendAReceiptDialog: true,
            sendReceiptLoading: false
        }
    }

    handleCloseSendAReceipt () {
       this.$router.push({ name: 'registration.login.root' })
    }

    async sendReceipt () {
        try {
            this.$data.sendReceiptLoading = true
            await this.$store.dispatch('event/sendReceipt', { event_identifier: this.$route.params.event_identifier, participant_token: this.$route.params.participant_token })
            this.$data.showSendAReceiptDialog = false
            Container.get(Notification).success('Your receipt has been sent.')
        } catch(e) {
            Container.get(Notification).error('There was an error sending your notification. Please try again.')
        } finally {
            setTimeout(() => {
               this.$router.push({ name: 'registration.login.root' })
            }, 3000)
        }
    }
}
